<template>
    <div
        @mouseover="over"
        @mouseleave="out"
        @click="playPause"
        :class="{ 'active': isOver }"
        title="Click to Play"
        class="lg:h-[600px]
                lg:px-4 rounded-lg lg:rounded-none voice relative overflow-hidden cursor-pointer">

        <img class="absolute z-0 object-cover w-full lg:h-[700px]" :src="voice.src" alt="Michael">

        <div
            class="absolute hidden lg:block cursor-pointer z-10 left-0 top-0 lg:w-[350px] h-full -bottom-10 opacity-0 transition-all duration-300 ml-4"
            :class="{ 'opacity-25': showPlayer }"
        >
            <VoicePlayer
                v-if="showPlayer"
                :file="`/voices/${voice.file}`" class="ml-auto" ref="player"/>
        </div>

        <audio controls class="hidden" ref="audio" @ended="playing = false">
            <source :src="`/voices/${voice.file}`" type="audio/mpeg">
        </audio>

        <div
            class="absolute bottom-5 left-4 lg:left-8 size-12 text-xs flex items-center gap-2 text-primary bg-white rounded-full p-2">
            <PlaySvg class="ml-1" v-if="!playing"/>
            <PauseSvg class="ml-1" v-else/>
        </div>

        <div class="relative z-10 px-6 py-4">
            <p class="text-2xl text-gray-700">
                {{ voice.name }}
            </p>

            <p class="voice-description">
                {{ voice.description }}
            </p>

            <p>
                <!--                        <AudioControl :file="`/voices/${voice.file}`" class="ml-auto"/>-->

            </p>
        </div>
    </div>
</template>
<script setup>
import VoicePlayer from "@/Pages/Index/Partials/VoicePlayer.vue";
import CircleProgress from "vue3-circle-progress";
import {ref, watch} from "vue";
import PlaySvg from "@/Components/PlaySvg.vue";
import PauseSvg from "@/Components/PauseSvg.vue";

const props = defineProps(['voice', 'number']);
const showPlayer = ref(false)
const player = ref()
const audio = ref()
const playing = ref(false);

let interval;
let timeout;

const showProgress = ref(false)
const progress = ref(0)

const isOver = ref(false)

const playPause = async () => {
    if (window.innerWidth < 1024) {
        if (audio.value.paused) {
            audio.value.play();
            playing.value = true;
        } else {
            audio.value.pause();
            playing.value = false;
        }
    } else {

        let wave = player.value?.wave;

        while (!wave) {
            await new Promise(resolve => setTimeout(resolve, 100))
            wave = player.value?.wave;
        }

        if (wave.playing) {
            wave.playing = false;
            wave.context.suspend();
            out();
        } else {
            wave.play();
            playing.value = true;
        }
    }
}

const over = () => {
    if (props.number > 0) {
        document.querySelector('.voice').classList.remove('active');
    }

    isOver.value = true;
    showProgress.value = true;

    if (!interval) {
        interval = setInterval(() => {
            if (progress.value < 100) {
                progress.value += 1;
            } else {
                clearInterval(interval)
            }
        }, 1000 / (100 / 2))
    }

    if (!timeout) {
        timeout = setTimeout(() => {
            showPlayer.value = true;
        }, 500)
    }
}

const out = () => {
    showProgress.value = false;
    isOver.value = false;

    document.querySelectorAll('.voice').forEach((el) => {
        el.classList.remove('active')
    });
    document.querySelector('.voice').classList.add('active');

    clearTimeout(timeout)
    timeout = null;

    clearInterval(interval)
    interval = null;

    showPlayer.value = false;

    const wave = player.value?.wave;

    if (wave && wave.playing) {
        wave.playing = false;
        wave.context.suspend();
    }
}
</script>
