<template>
    <svg
         width="40px" height="40px" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg" color="#000000" stroke-width="1.5">
        <path
            d="M6.90588 4.53682C6.50592 4.2998 6 4.58808 6 5.05299V18.947C6 19.4119 6.50592 19.7002 6.90588 19.4632L18.629 12.5162C19.0211 12.2838 19.0211 11.7162 18.629 11.4838L6.90588 4.53682Z"
            fill="#000000" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round"></path>
    </svg>
</template>
